import { render, staticRenderFns } from "./DeleteMarketGroupButton.vue?vue&type=template&id=75b14524&scoped=true"
import script from "./DeleteMarketGroupButton.vue?vue&type=script&lang=js"
export * from "./DeleteMarketGroupButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75b14524",
  null
  
)

export default component.exports