<template>
  <xzd-list-page
    ref="xzdListPage"
    module-name="marketGroup"
    :get-list-func="getList"
    :queryParam="queryParam"
    :show-export-excel-button="false"
    :show-import-excel-button="false"
    can-select>
    <div slot="searchBox">
      <marketGroup-list-search-box v-model="queryParam" @onSearch="xzdListPageReload"/>
    </div>
    <div slot="buttonsBox" slot-scope="{selectedRows}">
      <show-space>
        <save-marketGroup-button @xzdListPageReload="xzdListPageReload" />
        <delete-marketGroup-button :ids="selectedRows.map(v=>v.id)" @xzdListPageReload="xzdListPageReload" />
      </show-space>
    </div>
    <template slot="columns">
      <el-table-column prop="id" :label="$t('common.id')" width="100"/>
      <el-table-column prop="name" :label="$t('marketGroup.name')"/>
      <el-table-column prop="sort" :label="$t('marketGroup.sort')"/>
      <el-table-column  :label="$t('common.operate')" width="200" align="center">
        <template slot-scope="scope">
          <show-space>
            <save-marketGroup-button :id="scope.row.id" @xzdListPageReload="xzdListPageReload" />
            <delete-marketGroup-button :ids="[scope.row.id]" @xzdListPageReload="xzdListPageReload" />
          </show-space>
        </template>
      </el-table-column>
    </template>
  </xzd-list-page>
</template>
<script>
import {list as getList} from "@/api/marketGroup";
import MarketGroupListSearchBox from "./MarketGroupListSearchBox";
import SaveMarketGroupButton from "../buttons/SaveMarketGroupButton";
import DeleteMarketGroupButton from "../buttons/DeleteMarketGroupButton";

export default {
  name: "MarketGroupList",
  components: {DeleteMarketGroupButton, SaveMarketGroupButton, MarketGroupListSearchBox},
  data() {
    return {
      queryParam: {},
      getList
    }
  },
  methods: {
    xzdListPageReload(p = true) {
      this.$refs.xzdListPage.reload(p)
    }
  }
}

</script>
<style scoped>
</style>